import { buildGetUrl, parse } from 'utils/api'
import { defaultPromise } from 'utils/defaultValueHelper'
import { DevLogs } from 'utils/devLogs'
import { getPrinters as getDymoPrinters } from 'utils/print/dymo'
import { getPrinters as getJSPrintPrinters } from 'utils/print/jsPrint'
import { getPrinters as getZebraPrinters } from 'utils/print/zebra'
import { safeFetch, safeFetchJson, isJob } from 'utils/safeFetch'

import {
  GET_SETTINGS,
  GET_CLIENT_SOCKET,
  GET_APP_SETTINGS,
  GET_REACT_VERSION,
  GET_REACT_HUB_VERSION,
  GET_FRONT_URL,
  GET_MENU_ITEMS,
  GET_MY_ORGANIZATIONS,
  GET_SETTING_MENU_ITEMS,
  GET_CUSTOMER_PORTAL_VERSION,
  GET_PRINTERS,
  POST_SETTING,
  POST_SECURITY_SETTING,
  GET_SECURITY_SETTINGS,
} from './types'

/**
 * ! TODO (hdlaplante): Defaults were left here for TypeScript purposes but we could
 * ! rework it so they are built dynamically based on types when everything is typed properly.
 * ! The default would be initialized with nulls instead. For now, we have a DevLog.error that will
 * ! be triggered if a setting is set in the following dictionary but is not fetched from the database.
 */

const groups = {
  global: {
    defaultunits: {
      qty: 'un',
      weight: 'kg',
      length: 'm',
      surface: 'm2',
      volume: 'L',
      time: 'minute',
    },
    accounting_accounts: {},
    number_decimals: {
      measure_min: 2,
      measure_max: 5,
      price_min: 2,
      price_max: 5,
    },
    priceDigits: { minimumFractionDigits: 2, maximumFractionDigits: 5 },
    measureDigits: { minimumFractionDigits: 2, maximumFractionDigits: 5 },
    raw_material_title: { firstValue: 'material_title', secondValue: '', thirdValue: '' },
    location_display: { is_code: true, is_name: true },
    batch_print_measures: { max: 20, threshold: 20 },
    allowed_articles_groups: { value: ['Support', 'Admin', 'Gestion', 'Sales', 'Planner'] },
    allowed_financial_groups: { value: ['Support', 'Admin', 'Gestion', 'Planner'] },
    planning_horizon: { value: 30 },
    access: { early: false, manufacturing: true },
    contact_default_values: { defaultCurrency: false },
    inv_desc_edit: { value: false },
    zebra: { value: false },
    datamax: { value: false },
    legacy_print_inventory: { value: false },
    scan_sound: { value: false },
    card_type_custom_label: {},
  },
  operations: {
    is_ready_project_board: { value: false },
  },
  inventories: {
    partial_reception: { value: true },
    receive_max_ordered: { value: true },
    reception_target_plant: { value: true },
    inv_merge_diff_origin: { value: true },
    force_cost_on_order: { value: false },
    reception_auto_print: { value: false },
    reception_allow_custom_carrier: {},
    reception_is_show_weighings_section: { value: false },
    reception_is_apply_weight_on_unique_inventory: { value: false },
    pro_forma: { prefix: 'PRO', trimmed: false, is_enabled: false },
    force_scale_weight_groups: { value: [] },
    time_on_reception_date: { value: false },
    time_on_shipment_planned_date: { value: false },
    enable_reserve_on_production: { value: false },
    enable_shipment_sub_division: { value: false },
    reception_is_show_weighings_section: { value: null },
    reception_is_apply_weight_on_unique_inventory: { value: null },
  },
  salesOrders: {
    avoid_fractions: { value: false },
    disable_sales_order_items_cost_fetch: { value: false },
  },
  security: {
    disableRegularLogin: { value: false },
  },
  shipments: {
    shipment_bol_params: {
      allowGrouping: true,
      itemTitleField: 'title',
      expandInstruction: true,
      showListNumber: false,
      showSignatures: true,
      showPickedItems: true,
    },
    enforce_address_match_for_shipment_line_item: { value: false },
  },
  receptions: {
    reception_bol_params: {
      allowGrouping: true,
      itemTitleField: 'title',
      expandInstruction: true,
    },
    reception_custom_fields_on_pdf: { value: null },
    reception_allow_custom_carrier: { value: null },
  },
  email: {
    email_active: { value: false },
    email_region: { value: 'us-east-2' },
  },
  equipments: {
    equipments_number: { increment: 5, suffix: 5 },
    tools_number: { increment: 5, suffix: 5 },
  },
  resource: {
    'clockin': {
      promptComment: false,
      promptSlotSelector: false,
      forceCommentAfterShiftStart: false,
      forceCommentBeforeShiftEnd: false,
      forceCommentBeforeShiftStart: false,
      forceCommentAfterShiftEnd: false,
      shiftStartTimeLimit: 0,
      shiftStartTimeLimitBefore: 0,
      shiftEndTimeLimit: 1000,
      shiftEndTimeLimitAfter: 0,
      defaultBreakTime: 15,
      defaultDinerTime: 45,
    },
    'shift-saved-at-starthour': true,
    'manual_punch_entry': { value: false },
  },
  projects: {
    allow_bigger_qty: {
      selling_allow_over: false,
      buying_allow_over: false,
      selling_percentage_over: 0,
      buying_percentage_over: 0,
    },
    allow_sale_project_in_planning_wizard: { value: false },
  },
}

export const settingsStoreInitialState = {
  clientSocket: null,
  app: {
    env: null,
    appVersion: null,
    socketUrl: null,
  },
  menuItems: [],
  myOrganizations: [],
  settingMenuItems: [],
  frontUrl: null,
  reactVersion: null,
  reactHubVersion: null,
  customerPortalVersion: null,
  ...groups,
  printers: {
    dymo: [],
    dymoStatus: null,
    zebra: [],
    zebraStatus: null,
    datamax: [],
    datamaxStatus: null,
    isFetching: true,
  },
  groupKeys: Object.keys(groups),
}

const frontEndConfigKeys = [
  'priceDigits',
  'measureDigits',
]

export default function settingsReducer(state = settingsStoreInitialState, action) {
  const { payload } = action

  switch (action.type) {
  case GET_SECURITY_SETTINGS:
  case GET_SETTINGS: {
    const newState = { ...state }

    state.groupKeys.forEach((groupKey) => newState[groupKey] = { ...state[groupKey] } )

    payload.forEach((setting) => {
      const groupKey = getGroupKey(setting.name)
      newState[groupKey][setting.name] = getSettingValue(setting, groupKey)

      if (setting.name === 'number_decimals') {
        newState.global.measureDigits = {
          minimumFractionDigits: newState.global.number_decimals.measure_min,
          maximumFractionDigits: newState.global.number_decimals.measure_max,
        }
        newState.global.priceDigits = {
          minimumFractionDigits: newState.global.number_decimals.price_min,
          maximumFractionDigits: newState.global.number_decimals.price_max,
        }
      }
    })

    return newState
  }
  case GET_CLIENT_SOCKET: {
    return { ...state, clientSocket: payload }
  }
  case GET_APP_SETTINGS: {
    return { ...state, app: payload }
  }
  case GET_REACT_VERSION: {
    return { ...state, reactVersion: payload }
  }
  case GET_REACT_HUB_VERSION: {
    return { ...state, reactHubVersion: payload }
  }
  case GET_MY_ORGANIZATIONS: {
    return { ...state, myOrganizations: payload }
  }
  case GET_MENU_ITEMS: {
    return { ...state, menuItems: payload }
  }
  case GET_SETTING_MENU_ITEMS: {
    return { ...state, settingMenuItems: payload }
  }
  case GET_FRONT_URL: {
    return { ...state, frontUrl: payload }
  }
  case GET_CUSTOMER_PORTAL_VERSION: {
    return { ...state, customerPortalVersion: payload }
  }
  case GET_PRINTERS: {
    return { ...state, printers: payload }
  }
  case POST_SECURITY_SETTING:
  case POST_SETTING: {
    const newState = { ...state }

    if (payload && payload.name) {
      const groupKey = getGroupKey(payload.name)
      newState[groupKey] = { ...state[groupKey] }
      newState[groupKey][payload.name] = getSettingValue(payload, groupKey)
    }

    return newState
  }
  default: {
    return state
  }
  }
}

export async function fetchAppSettings(dispatch) {
  try {
    const settings = await (await safeFetch(`/app-info`)).json()
    dispatch({ type: GET_APP_SETTINGS, payload: settings })
  } catch (err) {
    console.error(err)
  }
}

export async function ping() {
  try {
    const resp = await fetch(`/keep-alive`, {
      redirect: 'manual',
    })
    const status = resp?.status
    if (!(status === 200 || status === 304 || status >= 500)) {
      document.location.reload()
    }
  } catch (err) {
    console.error(err)
  }
}

export async function fetchReactVersion(dispatch) {
  try {
    const result = await (await safeFetch(`/version/react`)).json()
    if (result.version) {
      dispatch({ type: GET_REACT_VERSION, payload: result.version })
    }
  } catch (err) {
    console.error(err)
  }
}

export async function fetchReactHubVersion(dispatch) {
  try {
    const result = await (await safeFetch(`/version/react-hub`)).json()
    if (result.version) {
      dispatch({ type: GET_REACT_HUB_VERSION, payload: result.version })
    }
  } catch (err) {
    console.error(err)
  }
}

function getOrganizationFields() {
  const appVersionTranslator = {
    'SmartDirector': 'al',
    'SmartSupervisor': 'al',
    'ALIXLarge': 'al',
    'ALIXMedium': 'am',
    'ALIXSmall': 'as',
    'Trial': 'trial',
  }
  return {
    appVersionName: { dbField: 'appversion_name' },
    appVersionKey: {
      parse: (organization) => {
        const appVersionKey = organization.appversion_name?.replace(/\./g, '').replace(/ /g, '')
        return appVersionTranslator[appVersionKey] || appVersionKey
      },
    },
    name: { dbField: 'client_title' },
    id: { dbField: 'client_id' },
  }
}

function parseOrganization(organization) {
  const fields = getOrganizationFields()
  const options = {
    defaultData: parse({}, { fields }),
    fields,
  }

  return parse(organization, options)
}

export async function fetchClients(dispatch) {
  let myOrganizations = []
  try {
    const result = await (await safeFetch(`/new_api/users/me/clients`)).json()
    if (result.isSuccess) {
      myOrganizations = result.result.map((organization) => parseOrganization(organization))
    }
  } catch (err) {
    console.error(err)
  }
  dispatch({ type: GET_MY_ORGANIZATIONS, payload: myOrganizations })
}

export async function fetchMenuItems(dispatch) {
  let menuItems = []
  try {
    menuItems = await (await safeFetch(`/api/login/session/menu-items`)).json()
  } catch (err) {
    console.error(err)
  }
  dispatch({ type: GET_MENU_ITEMS, payload: menuItems })
}

export async function fetchSettingMenuItems(dispatch) {
  let settingMenuItems = []
  try {
    settingMenuItems = await (await safeFetch(`/api/login/session/admin-menu-items`)).json()
  } catch (err) {
    console.error(err)
  }
  dispatch({ type: GET_SETTING_MENU_ITEMS, payload: settingMenuItems })
}

export async function fetchFrontUrl(dispatch) {
  try {
    const { isSuccess, result } = await (await safeFetch(`/front-url`)).json()
    if (isSuccess && result.url) {
      dispatch({ type: GET_FRONT_URL, payload: result.url })
    }
  } catch (err) {
    console.error(err)
  }
}

export async function fetchCustomerPortalVersion(dispatch) {
  try {
    const result = await (await safeFetch(`/version/customer-portal`)).json()
    if (result.version) {
      dispatch({ type: GET_CUSTOMER_PORTAL_VERSION, payload: result.version })
    }
  } catch (err) {
    console.error(err)
  }
}

export function fetchSettings(settingKeys) {
  return async function fetchSettingsThunk(dispatch) {
    const settings = await _fetchSettings(settingKeys)
    dispatch({ type: GET_SETTINGS, payload: settings })
    return settings
  }
}

export async function _fetchSettings(settingKeys) {
  let settings = []
  try {
    const result = await (await safeFetch(`/new_api/configs/${settingKeys}`)).json()

    if (result.isSuccess) {
      settings = result.result
    }
  } catch (err) {
    console.error(err)
  }

  return settings
}

export function fetchAllSettings() {
  return async function fetchAllSettingsThunk(dispatch) {
    const securitySettingsKeys = Object.keys(groups.security)
    const [configs, securitySettings] = await Promise.all([
      _fetchAllSettings(),
      _fetchSecuritySettings(securitySettingsKeys),
    ])
    const settings = [...configs, ...securitySettings]

    const groupKeys = Object.keys(groups)
    const payloadSettingKeys = settings.map(({ name }) => name)
    const errors = groupKeys.reduce((acc, groupKey) => {
      acc.push(
        ...Object.keys(groups[groupKey])
          .filter((settingKey) => !payloadSettingKeys.includes(settingKey))
          .map((settingKey) => ({ setting: settingKey, group: groupKey })),
      )
      return acc
    }, [])
    /**
     * ! (hdlaplante): if an error is triggered here it means the default
     * ! has not been properly initialized in the seed/migration.
     * ! All errors should be resolved during development.
     * ! If a setting is frontend only, it should be added to the frontEndConfigKeys array.
     */
    errors.forEach(({ setting, group }) => {
      if (!frontEndConfigKeys.includes(setting)) {
        DevLogs.error(
          'SettingFetchError',
          `Setting \`${setting}\` in group \`${group}\` was not initialized with a database value.`,
        )
      }
    })

    dispatch({ type: GET_SETTINGS, payload: settings })

    return settings
  }
}

export async function _fetchAllSettings() {
  let settings = []
  try {
    const result = await (await safeFetch(`/new_api/configs/`)).json()

    if (result.isSuccess) {
      settings = result.result
    }
  } catch (err) {
    console.error(err)
  }

  return settings
}

export function setClientSocket(socket) {
  return async function setClientSocketThunk(dispatch) {
    dispatch({ type: GET_CLIENT_SOCKET, payload: socket })
  }
}

export function setSettings(settings) {
  return async function setSettingsThunk(dispatch) {
    dispatch({ type: GET_SETTINGS, payload: settings })
  }
}

export function setSecuritySettings(settings) {
  return async function setSecuritySettingsThunk(dispatch) {
    dispatch({ type: GET_SECURITY_SETTINGS, payload: settings })
  }
}

export async function fetchClientDetails(type) {
  let details = {}
  try {
    const { isSuccess, result } = await safeFetchJson(buildGetUrl(
      '/new_api/configs/client-details',
      { isPreferred: true, type },
    ))
    if (isSuccess && result?.length > 0) {
      details = result[0]
    }
  } catch (err) {
    console.error(err)
  }

  return details
}

export const fetchPrinters = ({ isZebraEnabled, isDatamaxEnabled }) => {
  return async function fetchPrintersThunk(dispatch, getState) {
    const currentPrinters = getState().settings.printers
    dispatch({ type: GET_PRINTERS, payload: { ...currentPrinters, isFetching: true } })

    const printerPromise = (callback) => callback()
      .then((printers) => ({ printers, status: 'success' }))
      .catch(() => ({ printers: [], status: 'error' }))
    const [dymo, zebra, datamax] = await Promise.all([
      printerPromise(getDymoPrinters),
      defaultPromise(() => printerPromise(getZebraPrinters), isZebraEnabled, {}),
      defaultPromise(() => printerPromise(() => getJSPrintPrinters('datamax')), isDatamaxEnabled, {}),
    ])

    const result = {
      dymo: (dymo.printers || []).filter((obj) => obj.isConnected?.toString()?.toLowerCase() === 'true'),
      dymoStatus: dymo.status,
      zebra: zebra.printers || [],
      zebraStatus: zebra.status,
      datamax: datamax.printers || [],
      datamaxStatus: datamax.status,
      isFetching: false,
    }
    dispatch({ type: GET_PRINTERS, payload: result })
    return result
  }
}

export async function fetchGlobalConfig(configName) {
  try {
    const { isSuccess, result } = await safeFetchJson(`/api/config/global/${configName}`)
    if (isSuccess && !isJob(result)) {
      return result.value
    }
  } catch (err) {
    console.error(err)
  }
  return null
}

export function fetchSecuritySettings(settingKeys) {
  return async function fetchSecuritySettingsThunk(dispatch) {
    const settings = await _fetchSecuritySettings(settingKeys)
    dispatch({ type: GET_SECURITY_SETTINGS, payload: settings })
    return settings
  }
}

export async function _fetchSecuritySettings(settingKeys) {
  let settings = []
  try {
    const result = await (await safeFetch(`/new_api/configs/security/${settingKeys}`)).json()

    if (result.isSuccess) {
      settings = result.result
    }
  } catch (err) {
    console.error(err)
  }

  return settings
}

export function upsertSetting(settingKey, value) {
  return async function upsertSettingThunk(dispatch) {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ value }),
    }

    try {
      const result = await (await safeFetch(`/new_api/configs/${settingKey}`, requestOptions)).json()
      const [updated] = result.isSuccess ? result.result : []
      const error = !result.isSuccess ? result.result : null
      dispatch({ type: POST_SETTING, payload: updated, error, field: settingKey })
    } catch (error) {
      dispatch({ type: POST_SETTING, error, field: settingKey })
    }
  }
}

export function upsertSecuritySetting(settingKey, value) {
  return async function upsertSettingThunk(dispatch) {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ value }),
    }

    try {
      const result = await (await safeFetch(`/new_api/configs/security/${settingKey}`, requestOptions)).json()
      const [updated] = result.isSuccess ? result.result : []
      const error = !result.isSuccess ? result.result : null
      dispatch({ type: POST_SECURITY_SETTING, payload: updated, error, field: settingKey })
    } catch (error) {
      dispatch({ type: POST_SECURITY_SETTING, error, field: settingKey })
    }
  }
}

function getGroupKey(settingKey) {
  switch (settingKey) {
  case 'avoid_fractions': {
    return 'salesOrders'
  }
  case 'email_active':
  case 'email_region': {
    return 'email'
  }
  case 'equipments_number':
  case 'tools_number': {
    return 'equipments'
  }
  case 'time_on_reception_date':
  case 'time_on_shipment_planned_date':
  case 'force_scale_weight_groups':
  case 'pro_forma':
  case 'partial_reception':
  case 'receive_max_ordered':
  case 'reception_target_plant':
  case 'force_cost_on_order':
  case 'inv_merge_diff_origin':
  case 'enable_reserve_on_production':
  case 'enable_shipment_sub_division':
  case 'reception_auto_print':
  case 'reception_allow_custom_carrier':
  case 'reception_is_show_weighings_section':
  case 'reception_is_apply_weight_on_unique_inventory': {
    return 'inventories'
  }
  case 'is_ready_project_board': {
    return 'operations'
  }
  case 'clockin':
  case 'shift-saved-at-starthour':
  case 'manual_punch_entry': {
    return 'resource'
  }
  case 'allow_sale_project_in_planning_wizard':
  case 'allow_bigger_qty': {
    return 'projects'
  }
  case 'shipment_bol_params':
  case 'enforce_address_match_for_shipment_line_item':
    return 'shipments'
  case 'disableRegularLogin':
    return 'security'
  default: {
    return 'global'
  }
  }
}

function getSettingValue(setting) {
  if (setting.type === 'company') {
    return { [setting.company_id]: setting.value }
  } else {
    return setting.value
  }
}
